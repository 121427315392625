.container
    background: #f8f9fa
    width: 45vw
    margin: 16px
    border: 1px solid #cccccc
    border-radius: 24px
    & > nav
        padding: 10px
        & > div
            align-items: center
            & > h2
                margin: 0
                margin-left: 10px
                padding-top: 0.3125rem
                padding-bottom: 0.3125rem
                margin-right: 1rem
                font-size: 1rem
                line-height: inherit
                white-space: nowrap
                font-weight: 400
                white-space: pre-line
            & > p
                margin: 0
                white-space: pre-line
                margin-left: 10px
                padding-bottom: 0.3125rem
                margin-right: 1rem
                font-size: 0.7rem
                line-height: inherit
                white-space: nowrap
                font-weight: 400
            & > div
                margin-left: 10px
                margin-right: 1rem
                font-size: 0.7rem
                line-height: inherit
                white-space: nowrap
                font-weight: 400
                white-space: pre-line

.inline
    display: inline-block