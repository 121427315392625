@mixin wrapper()
   background: #e7e7e7 
   padding: 15px
   display: flex
   align-items: center
   justify-content: flex-end
   select
        width: 200px
        height: 30px

@mixin table()
    border-collapse: collapse
    width: 100%
    margin-bottom: 20px
    tr
        border: 1px solid #eee
        th, td
            width: 1%
            padding: 12px
        td:not(:first-child)
            text-align: center
        td:first-child
            font-weight: bold
    tr:nth-child(even)
        background-color: #f1f0f0
    padding: 20px

@mixin button()
    margin-top: 20px
    border: none
    background: #5a1088
    padding: 10px
    color: white
    font-weight: bold
    cursor: pointer
    transition: background 0.3s ease
    border-radius: 27px
    &:hover
        background: lighten(#5a1088, 20%)

.actionsWrapper
    display: flex
    align-items: center
    justify-content: center
    padding: 30px 0
    gap: 20px
    & > .loadButton
            @include button()

.wrapper
    @include wrapper()

.table
    @include table()