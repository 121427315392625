.header
    padding: 10px
    background: #f8f9fa
    position: relative
    z-index: 1
    & > nav
        & > div
            display: flex
            align-items: center
            & > h1
                margin: 0
                margin-left: 10px
                display: inline-block
                padding-top: 0.3125rem
                padding-bottom: 0.3125rem
                margin-right: 1rem
                font-size: 1.25rem
                line-height: inherit
                white-space: nowrap
                font-weight: 400