.header
    position: sticky
    top: 0
    background: #f8f9fa
    z-index: 1
    & > nav
        padding: 10px
        & > div
            align-items: center
            & > h2
                margin: 0
                margin-left: 10px
                padding-top: 0.3125rem
                padding-bottom: 0.3125rem
                margin-right: 1rem
                font-size: 1rem
                line-height: inherit
                white-space: nowrap
                font-weight: 400
                white-space: pre-line
            & > p
                margin: 0
                margin-left: 10px
                padding-bottom: 0.3125rem
                margin-right: 1rem
                font-size: 0.7rem
                line-height: inherit
                white-space: nowrap
                font-weight: 400
                white-space: pre-line
            & > div
                margin-left: 10px
                margin-right: 1rem
                font-size: 0.7rem
                line-height: inherit
                white-space: nowrap
                font-weight: 400
                white-space: pre-line

.underLine
    margin-top: 10px
    background: #cccccc
    width: auto
    height: 2px

.inline
    display: inline-block