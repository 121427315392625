@mixin wrapper()
   background: #e7e7e7 
   padding: 15px
   display: flex
   align-items: center
   justify-content: flex-end
   
   & > select
        width: 200px
        height: 30px

@mixin table()
    border-collapse: collapse
    width: 100%
    margin-bottom: 20px

    & > tr
        border: 1px solid #eee
        & >  th, td
            width: 1%
            padding: 12px
        td:not(:first-child)
            text-align: center
        td:first-child
            font-weight: bold

    & > tr:nth-child(even)
        background-color: #f1f0f0
    padding: 20px

.wrapper
    @include wrapper()

.table
    @include table()
    